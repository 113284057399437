import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styles from './BalanceSheet.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const BalanceSheet = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    assets: '',
    liabilities: '',
    equity: ''
  });
  const [editingId, setEditingId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchEntries = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/balance-sheet`, {
        params: { cooperativeId }
      });
      setEntries(response.data);
    } catch (err) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', err.response?.data || err.message);
    } finally {
      setLoading(false);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchEntries();
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setLoading(true);
    try {
      const entryToSubmit = {
        ...newEntry,
        date: new Date(newEntry.date).toISOString(),
        assets: parseFloat(newEntry.assets),
        liabilities: parseFloat(newEntry.liabilities),
        equity: parseFloat(newEntry.equity),
        cooperativeId
      };

      if (editingId) {
        await axios.put(`${API_BASE_URL}/api/balance-sheet/${editingId}`, entryToSubmit);
        setSuccess(t('entry_updated_successfully'));
      } else {
        await axios.post(`${API_BASE_URL}/api/balance-sheet`, entryToSubmit);
        setSuccess(t('entry_added_successfully'));
      }
      fetchEntries();
      setNewEntry({ date: '', assets: '', liabilities: '', equity: '' });
      setEditingId(null);
    } catch (err) {
      setError(err.response?.data?.message || t('error_saving_entry'));
      console.error('Error saving entry:', err.response?.data || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (entry) => {
    setNewEntry({
      ...entry,
      date: new Date(entry.date).toISOString().split('T')[0]
    });
    setEditingId(entry._id);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      setLoading(true);
      try {
        await axios.delete(`${API_BASE_URL}/api/balance-sheet/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        fetchEntries();
      } catch (err) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', err.response?.data || err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGeneratePDF = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/balance-sheet/generate-pdf`, {
        params: { cooperativeId },
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'balance_sheet.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSuccess(t('pdf_generated_successfully'));
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError(t('error_generating_pdf'));
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };

  
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('balance_sheet')}</h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
          <input
            type="date"
            name="date"
            value={newEntry.date}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="assets"
            value={newEntry.assets}
            onChange={handleInputChange}
            placeholder={t('assets')}
            required
            step="0.01"
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="liabilities"
            value={newEntry.liabilities}
            onChange={handleInputChange}
            placeholder={t('liabilities')}
            required
            step="0.01"
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="equity"
            value={newEntry.equity}
            onChange={handleInputChange}
            placeholder={t('equity')}
            required
            step="0.01"
            className="w-full p-2 border rounded"
          />
        </div>
        <button 
          type="submit" 
          disabled={loading}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
        >
          {editingId ? t('update_entry') : t('add_entry')}
        </button>
      </form>
      <button 
        onClick={handleGeneratePDF} 
        disabled={loading}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-4 disabled:opacity-50"
      >
        {t('generate_pdf')}
      </button>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">{t('date')}</th>
              <th className="px-4 py-2">{t('assets')}</th>
              <th className="px-4 py-2">{t('liabilities')}</th>
              <th className="px-4 py-2">{t('equity')}</th>
              <th className="px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {entries.map((entry) => (
              <tr key={entry._id} className="border-b">
                <td className="px-4 py-2">{new Date(entry.date).toLocaleDateString()}</td>
                <td className="px-4 py-2">${entry.assets.toFixed(2)}</td>
                <td className="px-4 py-2">${entry.liabilities.toFixed(2)}</td>
                <td className="px-4 py-2">${entry.equity.toFixed(2)}</td>
                <td className="px-4 py-2">
                  <button 
                    onClick={() => handleEdit(entry)} 
                    disabled={loading}
                    className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded mr-2 disabled:opacity-50"
                  >
                    {t('edit')}
                  </button>
                  <button 
                    onClick={() => handleDelete(entry._id)} 
                    disabled={loading}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded disabled:opacity-50"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {loading && <p className="mt-4 text-center">{t('loading')}</p>}
    </div>
  );
}; 
export default BalanceSheet;