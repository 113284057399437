import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './BookComponent.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const DeliveryNote = () => {
  const { cooperativeId } = useCooperative();
  const { t } = useTranslation();
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState({
    date: '',
    noteNumber: '',
    recipientName: '',
    items: '',
    quantity: '',
    condition: ''
  });
  const [editingNote, setEditingNote] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchDeliveryNotes = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const response = await axios.get(`${API_BASE_URL}/api/delivery-notes`, {
        params: { cooperativeId }
      });
      setNotes(response.data);
    } catch (error) {
      setError(t('error_fetching_delivery_notes'));
      console.error('Error fetching delivery notes:', error.response?.data || error.message);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchDeliveryNotes();
    }
  }, [cooperativeId, fetchDeliveryNotes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingNote) {
      setEditingNote(prev => ({ ...prev, [name]: value }));
    } else {
      setNewNote(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      const data = editingNote ? editingNote : newNote;
      const method = editingNote ? 'put' : 'post';
      const url = editingNote 
        ? `${API_BASE_URL}/api/delivery-notes/${editingNote._id}`
        : `${API_BASE_URL}/api/delivery-notes`;

      const requestData = { ...data, cooperativeId };

      await axios[method](url, requestData);

      setSuccess(editingNote ? t('note_updated_successfully') : t('note_added_successfully'));
      fetchDeliveryNotes();
      setNewNote({
        date: '',
        noteNumber: '',
        recipientName: '',
        items: '',
        quantity: '',
        condition: ''
      });
      setEditingNote(null);
    } catch (error) {
      setError(t('error_saving_delivery_note'));
      console.error('Error saving delivery note:', error.response?.data || error.message);
    }
  };

  const handleEdit = (note) => {
    setEditingNote(note);
    setNewNote(note);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/delivery-notes/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('note_deleted_successfully'));
        fetchDeliveryNotes();
      } catch (error) {
        setError(t('error_deleting_delivery_note'));
        console.error('Error deleting delivery note:', error);
      }
    }
  };

  
  const handleGeneratePDF = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/delivery-notes/pdf`, {
        params: { cooperativeId },
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'delivery-note.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setSuccess(t('pdf_generated_successfully'));
    } catch (err) {
      console.error('Error generating PDF:', err);
      setError(t('error_generating_pdf'));
    }
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };



  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('delivery_note')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <input
            type="date"
            name="date"
            value={newNote.date}
            onChange={handleInputChange}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="noteNumber"
            value={newNote.noteNumber}
            onChange={handleInputChange}
            placeholder={t('delivery_note_number')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="recipientName"
            value={newNote.recipientName}
            onChange={handleInputChange}
            placeholder={t('recipient_name')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="items"
            value={newNote.items}
            onChange={handleInputChange}
            placeholder={t('items_delivered')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="number"
            name="quantity"
            value={newNote.quantity}
            onChange={handleInputChange}
            placeholder={t('quantity')}
            required
            className="w-full p-2 border rounded"
          />
          <input
            type="text"
            name="condition"
            value={newNote.condition}
            onChange={handleInputChange}
            placeholder={t('condition')}
            required
            className="w-full p-2 border rounded"
          />
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
          {t('add_delivery_note')}
        </button>
      </form>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">{t('date')}</th>
              <th className="px-4 py-2">{t('delivery_note_number')}</th>
              <th className="px-4 py-2">{t('recipient_name')}</th>
              <th className="px-4 py-2">{t('items_delivered')}</th>
              <th className="px-4 py-2">{t('quantity')}</th>
              <th className="px-4 py-2">{t('condition')}</th>
              <th className="px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {notes.map((note) => (
              <tr key={note._id} className="border-b">
                <td className="px-4 py-2">{new Date(note.date).toLocaleDateString()}</td>
                <td className="px-4 py-2">{note.noteNumber}</td>
                <td className="px-4 py-2">{note.recipientName}</td>
                <td className="px-4 py-2">{note.items}</td>
                <td className="px-4 py-2">{note.quantity}</td>
                <td className="px-4 py-2">{note.condition}</td>
                <td className="px-4 py-2">
                  <button 
                    onClick={() => handleGeneratePDF(note._id)}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    {t('generate_pdf')}
                  </button>
                  <button 
                    onClick={() => handleDelete(note._id)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}; 
export default DeliveryNote;