import React from 'react';
import { useTranslation } from 'react-i18next';
import cooperativeImage from '../smilings.jpg';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow">
        <section className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-20">
  <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
    <div className="md:w-1/2 mb-10 md:mb-0">
      <h2 className="text-4xl md:text-5xl font-bold mb-4">{t('modern_cooperative_management')}</h2>
      <p className="text-xl mb-8">{t('kooplify_description')}</p>
      <button className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-blue-100 transition duration-300">
        {t('get_started')}
      </button>
    </div>
    <div className="md:w-1/2 md:pl-10">
      <img 
        src={cooperativeImage}
        alt="Cooperative Management" 
        className="rounded-lg shadow-xl transform hover:scale-105 transition duration-300"
      />
    </div>
  </div>
</section>

        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('features')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">{t('feature_title')}</h3>
                <p>{t('feature_description')}</p>
              </div>
              {/* Add more feature cards here */}
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('how_it_works')}</h2>
            <p className="text-lg text-center max-w-2xl mx-auto">{t('how_it_works_description')}</p>
            {/* Add content explaining how the platform works */}
          </div>
        </section>

        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('benefits')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('benefit_1_title')}</h3>
                <p>{t('benefit_1_description')}</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('benefit_2_title')}</h3>
                <p>{t('benefit_2_description')}</p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{t('benefit_3_title')}</h3>
                <p>{t('benefit_3_description')}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-blue-500 text-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('testimonials')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <blockquote className="bg-blue-600 p-6 rounded-lg">
                <p className="mb-4">{t('testimonial_1')}</p>
              </blockquote>
              <blockquote className="bg-blue-600 p-6 rounded-lg">
                <p className="mb-4">{t('testimonial_2')}</p>
              </blockquote>
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('faq')}</h2>
            <div className="max-w-2xl mx-auto">
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">{t('faq_1_question')}</h3>
                <p>{t('faq_1_answer')}</p>
              </div>
              <div className="mb-6">
                <h3 className="text-xl font-semibold mb-2">{t('faq_2_question')}</h3>
                <p>{t('faq_2_answer')}</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center">{t('contact_us')}</h2>
            <p className="text-lg text-center max-w-2xl mx-auto mb-8">{t('contact_description')}</p>
            {/* Add a contact form or contact information here */}
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {new Date().getFullYear()} Kooplify</p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;