import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './DebtorsBook.module.css'; 
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Set up axios to send the token with every request
axios.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token'); // or however you're storing the token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const DebtorsBook = () => {
  const { cooperativeId } = useCooperative();
  console.log('Current cooperativeId:', cooperativeId);

  const { t } = useTranslation();
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: '',
    debtorName: '',
    description: '',
    amountOwed: '',
    amountPaid: '',
    interestRate: '' 
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [duePayments, setDuePayments] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchEntries = useCallback(async () => {
    console.log('fetchEntries called, cooperativeId:', cooperativeId);
    if (!cooperativeId) {
      console.log('No cooperativeId, setting error');
      setError(t('no_cooperative_id'));
      return;
    }
    try {
      console.log('Making API request to fetch entries');
      const response = await axios.get(`${API_BASE_URL}/api/debtors`, {
        params: { cooperativeId }
      });
      console.log('API response received:', response.data);
      setEntries(response.data);
      console.log('Entries state updated');
    } catch (error) {
      setError(t('error_fetching_entries'));
      console.error('Error fetching entries:', error.response?.data || error.message);
    }
  }, [cooperativeId, t, API_BASE_URL]);
  
  useEffect(() => {
    if (cooperativeId) {
      fetchEntries();
    }
  }, [cooperativeId, fetchEntries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editingEntry) {
      setEditingEntry(prev => ({ ...prev, [name]: value }));
    } else {
      setNewEntry(prev => ({ ...prev, [name]: value }));
    }
  };


const handleSubmit = async (e) => {
  e.preventDefault();
  if (!cooperativeId) {
    setError(t('no_cooperative_id'));
    return;
  }
  try {
    const data = editingEntry ? editingEntry : newEntry;
    const method = editingEntry ? 'put' : 'post';
    const url = editingEntry 
      ? `${API_BASE_URL}/api/debtors/${editingEntry._id}`
      : `${API_BASE_URL}/api/debtors`;

    const parsedAmountOwed = parseFloat(data.amountOwed);
    const parsedInterestRate = parseFloat(data.interestRate);
    const parsedAmountPaid = parseFloat(data.amountPaid) || 0;
    const parsedPartialPayment = parseFloat(data.partialPayment) || 0;

    const totalPaid = editingEntry 
      ? (editingEntry.totalPaid || 0) + parsedPartialPayment
      : parsedAmountPaid;

    const interest = parsedAmountOwed * (parsedInterestRate / 100);
    const balance = (parsedAmountOwed + interest) - totalPaid;

    const requestData = { 
      ...data, 
      cooperativeId, 
      amountOwed: parsedAmountOwed,
      interestRate: parsedInterestRate,
      totalPaid,
      balance,
      partialPayment: parsedPartialPayment
    };

    const response = await axios[method](url, requestData);
    const updatedEntry = response.data;

    // Update the entries state
    setEntries(prevEntries => {
      if (editingEntry) {
        return prevEntries.map(entry => entry._id === editingEntry._id ? updatedEntry : entry);
      } else {
        return [updatedEntry, ...prevEntries];
      }
    });

    setSuccess(editingEntry ? t('entry_updated_successfully') : t('entry_added_successfully'));
    setNewEntry({ date: '', debtorName: '', description: '', amountOwed: '', amountPaid: '', interestRate: '', partialPayment: '' });
    setEditingEntry(null);
  } catch (error) {
    setError(t('error_saving_entry'));
    console.error('Error saving entry:', error.response?.data || error.message);
  }
};
  const handleEdit = (entry) => {
    setEditingEntry(entry);
    setNewEntry(entry);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t('confirm_delete'))) {
      try {
        await axios.delete(`${API_BASE_URL}/api/debtors/${id}?cooperativeId=${cooperativeId}`);
        setSuccess(t('entry_deleted_successfully'));
        setEntries((prevEntries) => prevEntries.filter((entry) => entry._id !== id));
      } catch (error) {
        setError(t('error_deleting_entry'));
        console.error('Error deleting entry:', error);
      }
    }
  };

  const checkDuePayments = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/debtors/check-due-payments?cooperativeId=${cooperativeId}`);
      setDuePayments(response.data);
    } catch (error) {
      setError(t('error_checking_due_payments'));
      console.error('Error checking due payments:', error);
    }
  };

  const showPaymentHistory = (entry) => {
    setSelectedEntry(entry);
  };

  const clearMessages = () => {
    setError('');
    setSuccess('');
  };
  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('debtors_book')}</h2>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error} 
          <button onClick={clearMessages} className="absolute top-0 right-0 px-4 py-3">&times;</button>
        </div>
      )}
      {success && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4">
          {success}
          <button onClick={clearMessages} className="absolute top-0 right-0 px-4 py-3">&times;</button>
        </div>
      )}
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <input
            type="date"
            name="date"
            value={editingEntry ? editingEntry.date : newEntry.date}
            onChange={handleInputChange}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="text"
            name="debtorName"
            value={editingEntry ? editingEntry.debtorName : newEntry.debtorName}
            onChange={handleInputChange}
            placeholder={t('debtor_name')}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="text"
            name="description"
            value={editingEntry ? editingEntry.description : newEntry.description}
            onChange={handleInputChange}
            placeholder={t('description')}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="number"
            name="amountOwed"
            value={editingEntry ? editingEntry.amountOwed : newEntry.amountOwed}
            onChange={handleInputChange}
            placeholder={t('amount_owed')}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="number"
            name="amountPaid"
            value={editingEntry ? editingEntry.amountPaid : newEntry.amountPaid}
            onChange={handleInputChange}
            placeholder={t('amount_paid')}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="number"
            name="interestRate"
            value={editingEntry ? editingEntry.interestRate : newEntry.interestRate}
            onChange={handleInputChange}
            placeholder={t('interest_rate')}
            required
            className="border rounded px-3 py-2"
          />
          <input
            type="number"
            name="partialPayment"
            value={newEntry.partialPayment}
            onChange={handleInputChange}
            placeholder={t('partial_payment')}
            className="border rounded px-3 py-2"
         />
        </div>
        <div className="mt-4">
          <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2">
            {editingEntry ? t('update_entry') : t('add_entry')}
          </button>
          {editingEntry && (
            <button 
              type="button" 
              onClick={() => setEditingEntry(null)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            >
              {t('cancel')}
            </button>
          )}
        </div>
      </form>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2">{t('date')}</th>
              <th className="px-4 py-2">{t('debtor_name')}</th>
              <th className="px-4 py-2">{t('description')}</th>
              <th className="px-4 py-2">{t('amount_owed')}</th>
              <th className="px-4 py-2">{t('amount_paid')}</th>
              <th className="px-4 py-2">{t('interest_rate')}</th>
              <th className="px-4 py-2">{t('balance')}</th>
              <th className="px-4 py-2">{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
              {entries.map((entry) => (
               <tr key={entry._id} className="border-b">
               <td className="px-4 py-2">{new Date(entry.date).toLocaleDateString()}</td>
               <td className="px-4 py-2">{entry.debtorName}</td>
               <td className="px-4 py-2">{entry.description}</td>
               <td className="px-4 py-2">{entry.amountOwed.toFixed(2)}</td>
               <td className="px-4 py-2">{(entry.totalPaid || 0).toFixed(2)}</td>
               <td className="px-4 py-2">{entry.interestRate}%</td>
               <td className="px-4 py-2">{entry.balance.toFixed(2)}</td>
               <td className="px-4 py-2">
                 <button 
                   onClick={() => handleEdit(entry)}
                   className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                   {t('edit')}
                  </button>
                  <button 
                     onClick={() => handleDelete(entry._id)}
                     className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    {t('delete')}
                  </button>
                  <button 
                     onClick={() => showPaymentHistory(entry)}
                     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                  >
                    {t('payment_history')}
                  </button>
               </td>
             </tr>
            ))}
         </tbody>
        </table>
      </div>
      <button 
        onClick={checkDuePayments} 
        className="mt-6 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
      >
        {t('check_due_payments')}
      </button>
      {duePayments.length > 0 && (
        <div className="mt-6 bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded">
          <h3 className="font-bold mb-2">{t('due_payments')}</h3>
          <ul>
            {duePayments.map((payment) => (
              <li key={payment._id}>
                {payment.debtorName}: {payment.balance.toFixed(2)}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedEntry && (
        <div className="mt-6 bg-gray-100 border border-gray-400 text-gray-700 px-4 py-3 rounded">
          <h3 className="font-bold mb-2">{t('payment_history_for')} {selectedEntry.debtorName}</h3>
          {selectedEntry.paymentHistory && selectedEntry.paymentHistory.length > 0 ? (
            <ul>
               {selectedEntry.paymentHistory.map((payment, index) => (
                 <li key={index}>
                    {new Date(payment.date).toLocaleDateString()}: {payment.amount.toFixed(2)}
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('no_payment_history')}</p>
            )}
            <button 
                onClick={() => setSelectedEntry(null)}
                className="mt-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
            >
              {t('close')}
            </button>
          </div>
        )}
    </div>
  );
};

export default DebtorsBook;