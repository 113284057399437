import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import styles from './MemberDirectory.module.css';
import { useCooperative } from '../contexts/CooperativeContext';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const MemberDirectory = () => {
  const { t } = useTranslation();
  const { cooperativeId } = useCooperative();
  const [searchTerm, setSearchTerm] = useState('');
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [newMember, setNewMember] = useState({
    name: '',
    role: '',
    contact: '',
    status: 'Active'
  });
  const [editingMember, setEditingMember] = useState(null);

  const fetchMembers = useCallback(async () => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/api/members`, {
        params: { cooperativeId }
      });
      setMembers(response.data);
    } catch (error) {
      setError(t('error_fetching_members'));
      console.error('Error fetching members:', error);
    } finally {
      setIsLoading(false);
    }
  }, [cooperativeId, t]);

  useEffect(() => {
    if (cooperativeId) {
      fetchMembers();
    }
  }, [cooperativeId, fetchMembers]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMember(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    setIsLoading(true);
    try {
      if (editingMember) {
        await axios.put(`${API_BASE_URL}/api/members/${editingMember._id}`, { ...newMember, cooperativeId });
      } else {
        await axios.post(`${API_BASE_URL}/api/members`, { ...newMember, cooperativeId });
      }
      fetchMembers();
      setNewMember({ name: '', role: '', contact: '', status: 'Active' });
      setEditingMember(null);
    } catch (error) {
      setError(t('error_saving_member'));
      console.error('Error saving member:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (member) => {
    setEditingMember(member);
    setNewMember({
      name: member.name,
      role: member.role,
      contact: member.contact,
      status: member.status
    });
  };

  const handleDelete = async (id) => {
    if (!cooperativeId) {
      setError(t('no_cooperative_id'));
      return;
    }
    if (window.confirm(t('confirm_delete'))) {
      setIsLoading(true);
      try {
        await axios.delete(`${API_BASE_URL}/api/members/${id}`, {
          params: { cooperativeId }
        });
        fetchMembers();
      } catch (error) {
        setError(t('error_deleting_member'));
        console.error('Error deleting member:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const filteredMembers = members.filter(member =>
    member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    member.role.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('member_directory')}</h2>
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <input
          type="text"
          name="name"
          value={newMember.name}
          onChange={handleInputChange}
          placeholder={t('name')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="role"
          value={newMember.role}
          onChange={handleInputChange}
          placeholder={t('role')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <input
          type="text"
          name="contact"
          value={newMember.contact}
          onChange={handleInputChange}
          placeholder={t('contact_info')}
          required
          className="w-full p-2 border border-gray-300 rounded"
        />
        <select
          name="status"
          value={newMember.status}
          onChange={handleInputChange}
          required
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Active">{t('active')}</option>
          <option value="Inactive">{t('inactive')}</option>
        </select>
        <div className="flex space-x-2">
          <button 
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {editingMember ? t('update_member') : t('add_member')}
          </button>
          {editingMember && (
            <button 
              type="button" 
              onClick={() => setEditingMember(null)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              {t('cancel')}
            </button>
          )}
        </div>
      </form>
      <input
        type="text"
        placeholder={t('search_members')}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full p-2 border border-gray-300 rounded mb-4"
      />
      {isLoading ? (
        <p className="text-center text-gray-600">{t('loading')}</p>
      ) : error ? (
        <p className="text-center text-red-600">{error}</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="p-3 text-left">{t('name')}</th>
                <th className="p-3 text-left">{t('role')}</th>
                <th className="p-3 text-left">{t('contact_info')}</th>
                <th className="p-3 text-left">{t('membership_status')}</th>
                <th className="p-3 text-left">{t('actions')}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {filteredMembers.map(member => (
                <tr key={member._id} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="p-3">{member.name}</td>
                  <td className="p-3">{member.role}</td>
                  <td className="p-3">{member.contact}</td>
                  <td className="p-3">{member.status}</td>
                  <td className="p-3">
                    <button 
                      onClick={() => handleEdit(member)}
                      className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                    >
                      {t('edit')}
                    </button>
                    <button 
                      onClick={() => handleDelete(member._id)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                    >
                      {t('delete')}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default MemberDirectory;